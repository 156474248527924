import * as React from "react"
import { Typography, Spin } from "antd"
import Layout from "../../components/layout"
import PageContent from "../../components/page-content"
import { Seo } from "../../components/seo"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { center } from "./index.module.css"

const { Title } = Typography

export default function Products() {
  const searchUrl = "/search?type=Product"
  return (
    <Layout>
      <Seo title="Products" />
      <Helmet>
        <meta httpEquiv="refresh" content={`0;url=${searchUrl}`} />
      </Helmet>
      <PageContent>
        <Title>Redirecting</Title>
        <div className={center}>
          <Spin size="large" />
          <div>
            Redirecting to <Link to={searchUrl}>products</Link>
          </div>
        </div>
      </PageContent>
    </Layout>
  )
}
